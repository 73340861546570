var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "individual-official-wrapper" },
    [
      !_vm.isMember
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c("p", { staticClass: "official-role" }, [
                  _vm._v("\n        " + _vm._s(_vm.official.role) + "\n      "),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "official-name" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.official.first_name) +
                  " " +
                  _vm._s(_vm.official.last_name) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "rowWrapper" },
        [
          _c("b-col", { class: _vm.colWrapper }, [
            _c(
              "div",
              { staticClass: "official-attribute" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "col-md-3 col-sm-12 col-xs-12" },
                      [_c("p", [_c("strong", [_vm._v("Address: ")])])]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "col-md-9 col-sm-12 col-xs-12" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.singleLineAddress()) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "col-md-3 col-sm-12 col-xs-12" },
                      [_c("p", [_c("strong", [_vm._v("Phone: ")])])]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "col-md-9 col-sm-12 col-xs-12" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.formattedPhoneNumber(
                                  _vm.official.phone_number
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "col-md-3 col-sm-12 col-xs-12" },
                      [
                        _c("p", { staticClass: "attribute-data" }, [
                          _c("strong", [_vm._v("Email: ")]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "col-md-9 col-sm-12 col-xs-12" },
                      [
                        _c("p", { staticClass: "overflow-control" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.official.email_address) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          !_vm.isMember
            ? _c(
                "b-col",
                { attrs: { md: "6", sm: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { fluid: "" } }, [
                        _c("p", [
                          _c("strong", [_vm._v("Driver's License #: ")]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { fluid: "" } }, [
                        _c("p", [_vm._v("\n            -\n          ")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { fluid: "" } }, [
                        _c("p", [_c("strong", [_vm._v("SSN: ")])]),
                      ]),
                      _c("b-col", { attrs: { fluid: "" } }, [
                        _c("p", [_vm._v("\n            -\n          ")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }