<template>
  <b-container class="individual-official-wrapper">
    <b-row v-if="!isMember">
      <b-col>
        <p class="official-role">
          {{ official.role }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="official-name">
          {{ official.first_name }} {{ official.last_name }}
        </p>
      </b-col>
    </b-row>
    <b-row class="rowWrapper">
      <b-col :class="colWrapper">
        <div class="official-attribute">
          <b-row>
            <b-col class="col-md-3 col-sm-12 col-xs-12">
              <p>
                <strong>Address: </strong>
              </p>
            </b-col>
            <b-col class="col-md-9 col-sm-12 col-xs-12">
              <p>
                {{ singleLineAddress() }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-3 col-sm-12 col-xs-12">
              <p>
                <strong>Phone: </strong>
              </p>
            </b-col>
            <b-col class="col-md-9 col-sm-12 col-xs-12">
              <p>
                {{ formattedPhoneNumber(official.phone_number) }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-3 col-sm-12 col-xs-12">
              <p class="attribute-data">
                <strong>Email: </strong>
              </p>
            </b-col>
            <b-col class="col-md-9 col-sm-12 col-xs-12">
              <p class="overflow-control">
                {{ official.email_address }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col v-if="!isMember" md="6" sm="12">
        <b-row>
          <b-col fluid>
            <p>
              <strong>Driver's License #: </strong>
            </p>
          </b-col>
          <b-col fluid>
            <p>
              -
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col fluid>
            <p>
              <strong>SSN: </strong>
            </p>
          </b-col>
          <b-col fluid>
            <p>
              -
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { addressFormatter } from '@/mixins/addressFormatter'
import { phoneNumberFormatter } from '@/mixins/phoneNumberFormatter'
import { mapGetters } from 'vuex'

export default {
  name: 'OfficialCard',
  mixins: [addressFormatter, phoneNumberFormatter],
  props: {
    official: Object,
  },
  computed: {
    isMember() {
      return ['member', 'manager'].includes(this.official.role.toLowerCase())
    },
    colWrapper() {
      return this.isMember ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-6 col-sm-6 col-xs-12'
    },
  },
  methods: {
    ...mapGetters('account', ['activeAccountServices']),
    singleLineAddress() {
      if (this.official.address === 'REGISTERED_AGENT_ADDRESS') {
        return this.formattedAddress(this.fetchRaAddress())
      }

      return this.formattedAddress((this.official.address))
    },
    formattedAddress(address) {
      if (address.line2) {
        return `${address.line1}, ${address.line2}, ${this.formatCityStateZip(address)}`
      }

      return `${address.line1}, ${this.formatCityStateZip(address)}`
    },
    formattedPhoneNumber(phoneNumber) {
      if (phoneNumber.length === 10) {
        return this.formatWithAreaCode(phoneNumber)
      }

      return phoneNumber
    },
    fetchRaAddress() {
      const raInfo = this.activeAccountServices().find(service => service.type === 'registered-agent')
      if (!raInfo) return null
      return raInfo.registered_agent_address
    },
  },
}
</script>

<style lang="scss" scoped>
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-4, $line-height: 120%) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: #363636;
  line-height: $line-height;
}

.individual-official-wrapper {
  height: 100%;
  background: white;
  border-radius: 0.625em;
  padding-top: 1.0em;

  .official-role {
    @include typography($font-size: 1.5em, $font-weight: $ct-ui-font-weight-7);
  }

  .official-name {
    @include typography($font-size: 1.25em, $font-weight: $ct-ui-font-weight-6);
  }

  .rowWrapper {
    justify-content: flex-start;
    align-items: center;
    height: 76%;

    .official-attribute {
      @include typography($line-height: 130%);
      height: 100%;

      p.overflow-control {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
