export const phoneNumberFormatter = {
  methods:  {
    formatWithAreaCode(phoneNumber) {
      const area = phoneNumber.slice(0, 3)
      const firstThree = phoneNumber.slice(3, 6)
      const lastFour = phoneNumber.slice(6, 10)

      return `(${area})-${firstThree}-${lastFour}`
    },
  },
}
