export const addressFormatter = {
  methods:  {
    formatCityStateZip(address) {
      const { city, state_province_region, zip_postal_code } = address

      if (city && state_province_region && zip_postal_code) {
        return `${address.city}, ${address.state_province_region} ${address.zip_postal_code}`
      }

      return ''
    },
  },
}
